import React from 'react';
import PropTypes, { string, object, number, shape } from 'prop-types';
import $ from 'jquery';

declare global {
  interface Window { embedpano: (...args: any) => void, removepano: (...args: any) => void }
}


const KRPanoJS = {
  embedpano: window.embedpano || (() => { console.log('no krpano lib loaded') }),
  removepano: window.removepano || (() => { console.log('no krpano lib loaded') }),
}

export interface ISnapshotData {
  picture: File;
  h: number;
  v: number;
  fov: number;
  scene: string;
}

const propTypes = {
  initvars: object,          // this variables will be set **before**
  // the xml file will be loaded and parsed
  xml: string.isRequired,
  basepath: string.isRequired,
  vars: object,               // this variables will be set **after**
  // the xml file will be loaded and parsed
  webglsetting: object,
  scene: string,
  lookAt: shape({ h: number, v: number })
}

type Props = PropTypes.InferProps<typeof propTypes> & React.HTMLAttributes<HTMLDivElement>;


class ReactKRPano extends React.Component<Props> {

  static defaultProps = {
    initvars: {},
    basepath: '/',
    vars: {},
    webglsetting: {}
  }

  private id: string
  private domID: string
  private krpanoInstanceRef: React.RefObject<HTMLDivElement>

  public async takeSnapshot(type: string = 'image/jpeg', quality: number = 0.8): Promise<ISnapshotData> {
    if (this.krpanoInstance) {
      const canvas = $(`#${this.domID} canvas`)[0] as HTMLCanvasElement
      const data = await new Promise<Blob | null>((ff, rj) => {
        canvas.toBlob((data) => ff(data), type, quality)
      })
      if (data) {

        const h = this.krpanoInstance.get('view.hlookat');
        const v = this.krpanoInstance.get('view.vlookat');
        const fov = this.krpanoInstance.get('view.fov');
        const scene = this.currentScene;
        const filename = `pic-${scene}.jpg`

        return {
          picture: new File([data], filename),
          h,
          v,
          fov,
          scene
        }
      } else {
        throw new Error(`cannot get file`)
      }
    } else {
      throw new Error(`krpano instance is not ready`);
    }
  }

  public get krpanoInstance() {
    return this.krpanoInstanceRef.current
      && $(`#${this.krpanoInstanceRef.current.id} > div`)[0] as any
  }

  public get(key: string) {
    return this.krpanoInstance && this.krpanoInstance.get(key);
  }

  public get currentScene() {
    return this.krpanoInstance && this.krpanoInstance.get('xml.scene');
  }

  constructor(props: Props) {
    super(props);
    this.id = `uuid`;
    this.domID = `domID`;
    this.krpanoInstanceRef = React.createRef();
  }

  componentDidMount() {

    KRPanoJS.embedpano({
      id: this.id,
      xml: this.props.xml,
      target: this.domID,
      html5: 'prefer+preserveDrawingBuffer',
      flash: 'never',
      basepath: this.props.basepath,
      vars: { showerrors: false, ...this.props.vars },
      webglsetting: this.props.webglsetting,
      passQueryParameters: false,
      onready: (krpano: any) => krpano.call(`loadscene("${this.props.scene}")`)
    });
  }

  componentWillUnmount() {
    KRPanoJS.removepano(this.id)
  }

  componentDidUpdate(prevProps: Props) {

    (prevProps.scene !== this.props.scene)
      && this.krpanoInstance
      && this.krpanoInstance.loadscene
      && this.krpanoInstance.loadscene(this.props.scene)

  }

  render() {

    const { style, ...others } = this.props;

    return (
      <div id={this.domID}
        style={{ ...style }}
        ref={this.krpanoInstanceRef} {...others} />
    )
  }

}


export default ReactKRPano;
