import { InferProps, number, shape, string } from 'prop-types';
import React from 'react';

const propTypes = {
  item: shape({
    rank: number,
    name: string,
    score: number
  }).isRequired,
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

type Props = InferProps<typeof propTypes>

const Polygon: React.FC<React.HTMLAttributes<HTMLElement> & { angle: number, height: number, width: number }> =
  ({ angle, height, width, style }) => {
    const xTranslation = (height * Math.tan(angle * Math.PI / 180));
    return <polygon
      style={style}
      points={`0,0 ${xTranslation},${height} ${width + xTranslation},${height} ${width},0`}
    />

  }

export default class LeaderboardItem extends React.Component<Props> {

  renderRectangle(
    translate: number,
    width: number,
    height: number,
    style: { fill?: string, stroke?: string, strokeWidth?: string }
  ) {
    return <polygon style={style} points={`0,0 ${translate},${height} ${width + translate},${height} ${width},0`} />
  }

  getColorByRank(rank: number | undefined | null) {
    switch (rank) {
      case 1: return '#f44b48';
      case 2:
      case 3: return '#62c2d0';
      default: return '#dbdbdb';
    }
  }

  getName() {
    const name = this.props.item.name;
    if (name) {
      return name.length > 17 ? (
        `${name.slice(0, 17)}...`) : (
          name
        )
    } else {
      return '';
    }
  }

  render() {
    return (
      <svg width={this.props.width} height={this.props.height}>
        <g transform='translate(15 5)'>
          <Polygon angle={15} width={175} height={44} color={'#0d549b'} style={{ fill: 'transparent', stroke: '#0d549b', strokeWidth: '1' }} />
          <text style={{ fontSize: '14px' }} x={174} y={28} textAnchor='end' fill='#0d549b'>{this.getName()}</text>
        </g>
        {<g>
          <Polygon angle={15} width={44} height={44} style={{ fill: this.props.color }} />
          <text x={28} y={30} textAnchor='middle' fontWeight='bold' style={{ fill: 'white', fontSize: '24px' }}>{this.props.item.rank}</text>
        </g>}
        <g transform='translate(25 42)'>
          <Polygon angle={15} width={38} height={24} style={{ fill: '#0d549b' }} />
          <text x={20} y={18} fontWeight='bold' textAnchor='middle' style={{ fill: 'white', fontSize: '14px' }} >
            {this.props.item.score && this.props.item.score}
          </text>
        </g>
      </svg>
    )
  }
}

