import { getEnv, types, Instance, SnapshotIn, applySnapshot, clone, flow, SnapshotOut } from 'mobx-state-tree';
import { AdminState, AdminStateSnapshotOut } from './AdminState';
import { Game, GameInstance } from './Game';
import { Cluster, ClusterInstance } from './Cluster';
import { SessionInstance, SessionSnapshotOut, Session } from './Session';
import { HotspotInstance } from './Hotspot';
import { User } from './User';
import { gql, NormalizedCacheObject } from 'apollo-boost';
import AdminQuery from '../../queries/AdminQuery';
import { ApolloClient } from 'apollo-client';
import AdminSessionSubscriptionQuery from '../../queries/AdminSessionSubscriptionQuery';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { SessionItemInstance } from './SessionItem';
import AdminListQuery from '../../queries/AdminListQuery';

export const AdminModel = types.model('AdminModel')
  .props({
    id: types.string,
    state: types.optional(AdminState, {}),
    selectedSession: types.optional(types.model({ session: types.maybeNull(Session) }), {}),
    loading: false,
    errors: types.array(types.string),
    selectedGame: types.maybeNull(Game),
    selectedCluster: types.maybeNull(Cluster),
    user: types.maybeNull(User),
    image: -1
  })

  .actions(self => ({
    setStateSnapshot(state: AdminStateSnapshotOut) {
      try {
        console.log("Setting snapshot");
        applySnapshot(self.state, state)
      } catch (error) {
        console.error(error)
      }
    },

    setSessionSnapshot(state: SessionSnapshotOut) {
      console.log(`Setting session ${state.id} snapshot`);
      applySnapshot(self.selectedSession, { session: state });
    },

    unselectSession() {
      self.selectedSession.session = null;
    },

    setLoading(value: boolean) {
      self.loading = value;
    },

    setErrors(errors: string[]) {
      self.errors.replace(errors);
    }

  }))

  .views(self => ({
    get client() {
      return getEnv<{ client: ApolloClient<NormalizedCacheObject> }>(self)
        .client
    },
    get wsClient() {
      return getEnv<{ wsClient: SubscriptionClient }>(self)
        .wsClient
    }
  }))

  .actions(self => ({
    fetch: flow(function*() {
      self.setLoading(true);
      yield self.client
        .query({ query: AdminListQuery, variables: {}, fetchPolicy: 'no-cache' })
        .then((state) => {
          if (state.errors && state.errors.length) {
            self.setErrors(state.errors.map(err => err.message));
          } else {
            self.setErrors([]);
            self.setStateSnapshot(state.data);
          }
        })
        .catch(err => self.setErrors([err.message]))
        .then(() => {
          self.setLoading(false);
        })
    }),

    fetchSession: flow(function*(sessionId: string) {
      self.setLoading(true);
      yield self.client.query({
        query: AdminQuery, variables: { sessionID: sessionId }, fetchPolicy: 'no-cache'
      }).then((session) => {
        console.log(session)
        if (session.errors && session.errors.length) {
          self.setErrors(session.errors.map(err => err.message));
        } else {
          self.setErrors([]);
          self.setSessionSnapshot(session.data.session);
        }
      })
        .catch(err => self.setErrors([err.message]))
        .then(() => {
          self.setLoading(false);
        })
    }),

    subscribe(): ZenObservable.Subscription | undefined {
      if (!self.selectedSession.session) {
        console.error("Cannot subscribe without a session selected")
        return undefined
      }
      return self.client.subscribe({
        query: AdminSessionSubscriptionQuery,
        variables: { sessionID: self.selectedSession.session.id }
      }).subscribe((state) => {
        if (state.errors) {
          console.log(state.errors);
          self.setErrors(state.errors.map((err) => err.message));
        } else {
          self.setErrors([]);
          self.setSessionSnapshot(state.data.sessionChanged);
        }
      });
    }
  }))

  .actions(self => ({
    afterCreate() {
      self.fetch();
    },

    addSession(name: string) {
      self.client.mutate({
        mutation: gql`
          mutation CreateSession($sessionID:ID!, name:String!) {
            createSession( sessionID: $sessionID, name:$name ) {
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id,
          name
        }
      })
    },
    deleteSession(sessionID: string) { console.log(sessionID) },

  }))

  .actions(self => ({
    setSelectedGame(game: GameInstance) {
      self.selectedGame = clone(game);
      self.selectedCluster = null;
    },
    setSelectedCluster(cluster: ClusterInstance) {
      self.selectedCluster = clone(cluster);
    },

    showPicture(id: number) {
      self.image = id;
    },

    hidePicture() {
      self.image = -1;
    }
  }))

  .views(self => ({
    getSessionItem(sessionID: string): SessionItemInstance | null {
      const session = self.state.sessions.find(session => session.id === sessionID);
      return session || null;
    },
  }))

  .views(self => ({
    getHotspot(hotspotID: string) {
      const session = self.selectedSession.session;
      if (session) {
        const hotspot = session.hotspots && session.hotspots.find((hotspot: HotspotInstance) => hotspot.id === hotspotID);
        return hotspot || null;
      }
      return null;
    },

    getGamesWithSolution() {
      const session = self.selectedSession.session;
      return session ? [...session.games /** aggiungere self.state.solutions */] : []
    }
  }))

export interface AdminModelInstance extends Instance<typeof AdminModel> { }
export interface AdminModelSnapshotIn extends SnapshotIn<typeof AdminModel> { }
export interface AdminModelSnapshotOut extends SnapshotOut<typeof AdminModel> { }
