import React from 'react';
import { IntlProvider } from 'react-intl';
import ReactResponsive from 'react-responsive';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import './App.css';
import HydroplantHeader from './components/HydroplantHeader';
import { Layout, LayoutProvider } from './components/Layout';
import LowRes from './components/LowRes';
import en from './dictionaries/en.json';
import HydroPlantLayoutContext from './layout';
import { StoreProvider } from './model/StoreProvider';
import RouteGame from './routes/RouteGame';
import RouteGameOnboarding from './routes/RouteGameOnboarding';
import RouteGameReport from './routes/RouteGameReport';
import RouteSignin from './routes/RouteSignin';

const AppGame: React.FC<{ mountPath: string }> = ({ mountPath }) => {
  return (
    <div className="App">
      <StoreProvider>
        <IntlProvider locale="en" messages={en}>
          <LayoutProvider context={HydroPlantLayoutContext}>
            <ReactResponsive query="(min-width: 780px)">

              <Layout>
                {({ components: { Page } }) => (
                  <Page style={{ width: "100%" }}>
                    <Page.Header
                      style={{
                        backgroundColor: '#002855',
                        height: '100px',
                        width: '100%',
                        color: 'white',
                        display: 'flex',
                        padding: '0 33px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        zIndex: 99999,
                        fontWeight: 400,
                        fontSize: '20px',
                        letterSpacing: 0,
                        lineHeight: '22px',
                      }}
                    >
                      <HydroplantHeader />
                    </Page.Header>
                    <Page.Layer style={{ position: 'relative', top: '0', bottom: 0, width: '100%', background: 'linear-gradient( white, #e8f9fb 51%)' }}>
                      <Switch>
                        <Route
                          path={`${mountPath}/games/:gameID/onboarding`}
                          component={RouteGameOnboarding}
                        />
                        <Route
                          path={`${mountPath}/games/:gameID/report`}
                          component={RouteGameReport}
                        />
                        {/* <Route
                        path={`${mountPath}/games/:gameID/plant-map`}
                        component={RouteGamePlantMap}
                      />
                      <Route
                        path={`${mountPath}/games/:gameID/virtual-tour`}
                        component={RouteGameVirtualTour}
                      /> */}
                        <Route path={`${mountPath}/games/:gameID/signin`} component={RouteSignin} />
                        <Route path={`${mountPath}/games/:gameID`} component={RouteGame} />
                      </Switch>
                    </Page.Layer>
                  </Page>
                )}
              </Layout>
            </ReactResponsive>
            <ReactResponsive query="(max-width: 779px)">
              <LowRes />
            </ReactResponsive>
          </LayoutProvider>
        </IntlProvider>
      </StoreProvider>
    </div>
  );
}
export default AppGame;
