import { createBrowserHistory } from "history";
import React from "react";
import { IntlProvider } from "react-intl";
import ReactResponsive from "react-responsive";
import { Switch } from "react-router";
import { Route, Router } from "react-router-dom";
import "./App.css";
import HydroplantHeader from "./components/HydroplantHeader";
import { Layout, LayoutProvider } from "./components/Layout";
import LowRes from "./components/LowRes";
import en from "./dictionaries/en.json";
import HydroPlantLayoutContext from "./layout";
import { StoreProvider } from "./model/AdminStoreProvider";
import RouteSession from "./routes/RouteSession";
import RouteSessionDebrief from "./routes/RouteSessionDebrief";
import RouteSessions from "./routes/RouteSessions";

const history = createBrowserHistory({
  basename: window._env_.BASEPATH,
});

const AppAdmin: React.FC<{ mountPath: string }> = ({ mountPath }) => {
  return (
    <div className="App">
      <StoreProvider>
        <IntlProvider locale="en" messages={en}>
          <LayoutProvider context={HydroPlantLayoutContext}>
            <ReactResponsive query="(min-width: 780px)">
              <Layout>
                {({ components: { Page } }) => (
                  <Page
                    className="admin-page"
                    style={{ width: "100%", minHeight: "100vh" }}
                  >
                    <Page.Header
                      style={{
                        backgroundColor: "#002855",
                        height: "100px",
                        width: "100%",
                        color: "white",
                        display: "flex",
                        padding: "0 33px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        zIndex: 99999,
                        fontWeight: 400,
                        fontSize: "20px",
                        letterSpacing: 0,
                        lineHeight: "22px",
                      }}
                    >
                      <HydroplantHeader />
                    </Page.Header>
                    <Page.Layer
                      style={{
                        minHeight: "calc(100vh - 100px)",
                        background: "linear-gradient( white, #e8f9fb 51%)",
                        position: "relative",
                        padding: "35px 16% 167px 16%",
                      }}
                    >
                      <Router history={history}>
                        <Switch>
                          <Route
                            path={`${mountPath}/users/:userID/sessions/:sessionID/debrief`}
                            component={RouteSessionDebrief}
                          />
                          <Route
                            path={`${mountPath}/users/:userID/sessions/:sessionID/`}
                            component={RouteSession}
                          />
                          <Route
                            path={`${mountPath}/users/:userID/sessions`}
                            component={RouteSessions}
                          />
                        </Switch>
                      </Router>
                    </Page.Layer>
                  </Page>
                )}
              </Layout>
            </ReactResponsive>
            <ReactResponsive query="(max-width: 779px)">
              <LowRes />
            </ReactResponsive>
          </LayoutProvider>
        </IntlProvider>
      </StoreProvider>
    </div>
  );
};
export default AppAdmin;
