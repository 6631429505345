import React, { Fragment, useEffect } from 'react';
import { AdminModelInstance } from "../model/AdminModel";
import { observer } from 'mobx-react';
import { useStore } from '../model/AdminStoreProvider';

type Props = {
  userID: string;
  sessionID?: string;
  children: React.SFC<{ admin: AdminModelInstance }>;
};

const Admin : React.SFC<Props> = ( props ) => {

  const store = useStore();
  const admin = store.getAdminByID(props.userID);

  let sub = React.useRef<ZenObservable.Subscription | undefined>();

  useEffect(() => {
    store.loadAdminByID(props.userID);

    if (admin && props.sessionID) {
      if (admin.selectedSession.session && admin.selectedSession.session.id === props.sessionID) {
        return;
      }
      admin.fetchSession(props.sessionID).then(() => {
        if (sub.current) sub.current.unsubscribe()
        sub.current = admin.subscribe()
      });
    }
  })

  return (
    <Fragment>
      {admin && props.children && props.children({ admin })}
    </Fragment>
  )
}

export default observer(Admin);
