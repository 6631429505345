import React, { HTMLAttributes, FC } from 'react';

const LeaderboardContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => (
  <div style={{ padding: '3rem 2rem', backgroundColor: 'white' }}>
    <div style={{ height: "500px", overflowY: "auto", overflowX: "hidden" }}>
      {children}
    </div>
  </div>
)

export default LeaderboardContainer;
