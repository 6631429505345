import { observer } from 'mobx-react';
import React, { FC, Fragment, HTMLAttributes } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import LOCKOFF from "../assets/lock-off.png";
import LOCKON from "../assets/lock-on.png";
import Admin from '../components/Admin';
import AdminButton from '../components/AdminButton';
import AdminSectionHeader from '../components/AdminSectionHeader';
import BackArrowIcon from '../components/BackArrowIcon';
import DocumentsUploader from '../components/DocumentsUploader';
import EditableDocList from '../components/EditableDocList';
import GamesList from '../components/GamesList';
import HydroplantToggle from '../components/HydroplantToggle';
import { Layout } from '../components/Layout';
import Leaderboard from '../components/Leaderboard';
import LeaderboardContainer from '../components/LeaderboardContainer';
import { SessionInstance } from '../model/AdminModel/Session';


export type RouteSessionProps = RouteComponentProps<{ userID: string, sessionID: string }>;


const AdminSection: FC<HTMLAttributes<HTMLDivElement>> = ({ children, style, ...others }) => <div style={{ margin: '2rem 0', ...style }} {...others}>{children}</div>


class RouteSession extends React.Component<RouteSessionProps> {

  renderButtons(session: SessionInstance) {
    return (
      <Layout>
        {
          ({ components: { Image } }) => (
            <div style={{ textAlign: 'right' }}>

              <AdminButton
                color={
                  session.isTourOpen ? '#f44b48' : '#0d549b'
                }
                onClick={() => {
                  session.isTourOpen
                    ? session.closePlantTour()
                    : session.openPlantTour()
                }}>
                <Image src={session.isTourOpen ? LOCKOFF : LOCKON} style={{ verticalAlign: 'middle', width: 16, height: 16, display: 'inline-block', marginRight: '0.6rem' }} />
                <div style={{ verticalAlign: 'middle', display: 'inline-block' }}><FormattedHTMLMessage id={`admin.plantTour:${session.isTourOpen ? 'close' : 'open'}`} /></div>
              </AdminButton>
              <Link to={`/admin/users/${this.props.match.params.userID}/sessions/${this.props.match.params.sessionID}/debrief`}>
                <AdminButton color='#0d549b'>
                  <FormattedHTMLMessage id="session.debrief:text" />
                </AdminButton>
              </Link>
            </div >
          )
        }

      </Layout>
    )
  }

  render() {
    return (
      <Layout>
        {({ components: { Header, Page } }) => (
          <Admin userID={this.props.match.params.userID} sessionID={this.props.match.params.sessionID}>
            {({ admin }) => {
              const session = admin.selectedSession.session;
              if (session) {
                return (
                  <Fragment>
                    <AdminSection style={{ marginTop: '1rem' }}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <AdminSectionHeader style={{ textTransform: 'capitalize' }} >{session.name}</AdminSectionHeader>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {this.renderButtons(session)}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <GamesList
                        style={{ backgroundColor: 'white', padding: '2rem 7%' }}
                        games={session.games} />
                    </AdminSection>
                    <AdminSection style={{ marginTop: '4rem' }}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={8} verticalAlign={'bottom'}>
                            <AdminSectionHeader>
                              <FormattedHTMLMessage id="uploadDocuments:title" />
                            </AdminSectionHeader>
                          </Grid.Column>
                          <Grid.Column width={6} floated='right' verticalAlign={'bottom'} style={{ marginBottom: '2rem' }}>
                            <Header color='red'>
                              <FormattedHTMLMessage id='uploadedDocuments:title' />
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <DocumentsUploader onUpload={(file: File) => session.addSessionDocument(file)} />
                          </Grid.Column>
                          <Grid.Column width={6} floated='right'>
                            <EditableDocList
                              baseUrl={window._env_.PUBLIC_URL}
                              documents={session.documents}
                              edit={true}
                              onDelete={documentID => { session.removeSessionDocument(documentID) }} />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </AdminSection>
                    <AdminSection style={{ marginTop: '4rem' }}>
                      <Grid>
                        <Grid.Column width={8} style={{ display: 'flex', alignItems: 'center' }} >
                          <AdminSectionHeader>
                            <FormattedHTMLMessage id="leaderboard:title" />
                          </AdminSectionHeader>
                        </Grid.Column>
                        <Grid.Column width={8} style={{
                          textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                        }}>
                          <HydroplantToggle checked={session.isLeaderboardEnabled} onToggleClick={() => {
                            if (!session.isLeaderboardEnabled) {
                              session.showLeaderboard()
                            } else {
                              session.hideLeaderboard()
                            }
                          }} />
                        </Grid.Column>
                      </Grid>

                      <LeaderboardContainer>
                        <Leaderboard leaderboard={session.leaderboard} />
                      </LeaderboardContainer>
                      <Page.BottomBar
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          position: "absolute",
                          width: "100%",
                          height: '62px',
                          bottom: "0",
                          left: "0"
                        }}>
                        <Grid
                          stretched
                          verticalAlign="middle"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            margin: 0,
                            padding: "0 1rem",
                            height: '100%'
                          }}
                        >
                          <Grid.Column
                            width={16}
                            style={{
                              padding: 0
                            }}
                          >
                            <Link
                              to={`/admin/users/${this.props.match.params.userID}/sessions`}
                              style={{ color: "#a2a2a2", display: 'flex' }}
                            >
                              <BackArrowIcon style={{ display: 'inline-block', marginRight: '10px' }} />
                              <FormattedHTMLMessage id="backToSessions:text" />
                            </Link>
                          </Grid.Column>
                        </Grid>
                      </Page.BottomBar>
                    </AdminSection>
                  </Fragment>
                )
              } else {
                return null
              }
            }}
          </Admin>
        )}
      </Layout>
    )
  }
}

export default observer(RouteSession);
