import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";
import { SessionItem } from "./SessionItem";

export const AdminState = types.model('AdminState')
  .props({
    sessions: types.array(SessionItem),
  })

export interface AdminStateInstance extends Instance<typeof AdminState> { }
export interface AdminStateSnapshotIn extends SnapshotIn<typeof AdminState> { }
export interface AdminStateSnapshotOut extends SnapshotOut<typeof AdminState> { }
