import { getEnv, types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";
import { Picture } from "./Picture";
import { Cluster } from "./Cluster";
import { Document } from "./Document";
import { gql, NormalizedCacheObject } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import sortBy from 'lodash/sortBy';
import { Hotspot } from './Hotspot';

export const Game = types.model('Game')
  .props({
    id: types.string,
    teamName: types.maybeNull(types.string),
    teamLeader: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    completed: types.boolean,
    isTourOpen: types.boolean,
    reportDocuments: types.array(Document),
    pictures: types.array(Picture),
    clusters: types.array(Cluster),
    rankedClusters: types.array(types.model({ clusterID: '', rank: -1 })),
    discoveredHotspots: types.array(Hotspot)
  })

  .views(self => ({
    get client() {
      return getEnv<{ client: ApolloClient<NormalizedCacheObject> }>(self)
        .client
    },
    get rankedClustersSorted() {
      return sortBy(self.rankedClusters, 'rank').map(cl => cl.clusterID)
    }
  }))


  .actions(self => ({
    deleteGame() {
      self.client.mutate({
        mutation: gql`
          mutation DeleteGame($gameID:ID!) {
            deleteGame( gameID: $gameID ) {
              rejectionReason
            }
          }
        `,
        variables: {
          gameID: self.id
        }
      })
    },

    closePlantTour() {
      self.client.mutate({
        mutation: gql`
          mutation CloseGameTour($gameID:ID!) {
            closeGameTour( gameID: $gameID ) {
              rejectionReason
            }
          }
        `,
        variables: {
          gameID: self.id
        }
      })
    },

    openPlantTour() {
      self.client.mutate({
        mutation: gql`
          mutation OpenGameTour($gameID:ID!) {
            openGameTour( gameID: $gameID ) {
              rejectionReason
            }
          }
        `,
        variables: {
          gameID: self.id
        }
      })
    },

    uncompleteGame() {
      self.client.mutate({
        mutation: gql`
          mutation UncompleteGame($gameID:ID!) {
            uncompleteGame( gameID: $gameID ) {
              rejectionReason
            }
          }
        `,
        variables: {
          gameID: self.id
        }
      })
    },
  }))
  .actions(self => ({
    togglePlantTour() {
      self.isTourOpen ? self.closePlantTour() : self.openPlantTour()
    }
  }))

export interface GameInstance extends Instance<typeof Game> { }
export interface GameSnapshotIn extends SnapshotIn<typeof Game> { }
export interface GameSnapshotOut extends SnapshotOut<typeof Game> { }
