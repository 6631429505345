import React from 'react';
import { IntlProvider } from 'react-intl';
import ReactResponsive from 'react-responsive';
import { Switch } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import HydroplantHeader from './components/HydroplantHeader';
import { Layout, LayoutProvider } from './components/Layout';
import LowRes from './components/LowRes';
import en from './dictionaries/en.json';
import HydroPlantLayoutContext from './layout';
import { StoreProvider } from './model/StoreProvider';
import RouteHome from './routes/RouteHome';


const AppLogin: React.FC<{ mountPath: string }> = ({ mountPath }) => {
  return (
    <div className="AppLogin">
      <StoreProvider>
        <IntlProvider locale="en" messages={en}>
          <LayoutProvider context={HydroPlantLayoutContext}>
            <ReactResponsive query="(min-width: 780px)">

              <Layout>
                {({ components: { Page } }) => (
                  <Page style={{ width: "100%", height: "100%" }}>
                    <Page.Header
                      style={{
                        backgroundColor: '#002855',
                        height: '100px',
                        width: '100%',
                        color: 'white',
                        display: 'flex',
                        padding: '0 33px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        zIndex: 99999,
                        fontWeight: 400,
                        fontSize: '20px',
                        letterSpacing: 0,
                        lineHeight: '22px',
                      }}
                    >
                      <HydroplantHeader light />
                    </Page.Header>
                    <Page.Layer style={{ position: 'absolute', top: '100px', bottom: 0, width: '100%' }}>
                      <BrowserRouter>
                        <Switch>
                          <Route path={`/`} component={RouteHome} />
                        </Switch>
                      </BrowserRouter>
                    </Page.Layer>
                  </Page>
                )}
              </Layout>
            </ReactResponsive>
            <ReactResponsive query="(max-width: 779px)">
              <LowRes />
            </ReactResponsive>
          </LayoutProvider>
        </IntlProvider>
      </StoreProvider>
    </div>
  );
}
export default AppLogin;
