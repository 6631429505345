import { gql } from "apollo-boost";

export default gql`
  query AdminListQuery {
    sessions {
      id
      name
      date
      games {
        id
      }
    }
  }
`;
