import { gql, split } from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { flow } from "mobx";
import { observer } from "mobx-react";
import {
  applySnapshot,
  getPath,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import React, { Fragment } from "react";
import GameQuery from "../../queries/GameQuery";
import { SyncModel } from "../SyncModel";
import { Cluster, ClusterInstance } from "./Cluster";
import { GameState, GameStateSnapshotOut } from "./GameState";
import { HotspotInstance } from "./Hotspot";
import GameSubscriptionQuery from "../../queries/GameSubscriptionQuery";
import { getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";

const httpLink = createUploadLink({
  uri: window._env_.API_ENDPOINT,
});

const wsClient = new SubscriptionClient(
  window._env_.API_ENDPOINT.replace("https", "wss").replace("http", "ws"),
  {
    reconnect: true,
    reconnectionAttempts: 5,
    lazy: true,
  },
)
const wsLink = new WebSocketLink(wsClient);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

const SyncCluster = SyncModel(Cluster);

export const GameModel = types
  .model("GameModel")
  .props({
    id: types.string,
    state: types.optional(GameState, {}),
    loading: false,
    errors: types.array(types.string),
    syncSelectedCluster: types.maybeNull(SyncCluster),
    syncClusterInEditing: types.maybeNull(SyncCluster),
    sidebar: false,
    image: -1,
    clusterEditMode: types.maybeNull(types.string),
    /* editMode: types.maybeNull(types.enumeration(['name', 'unit'])) */
  })

  .actions((self) => ({
    setStateSnapshot(state: GameStateSnapshotOut) {
      try {
        applySnapshot(self.state, state);
      } catch (error) {
        console.error(error);
      }
    },

    setLoading(value: boolean) {
      self.loading = value;
    },

    setErrors(errors: string[]) {
      self.errors.replace(errors);
    },
  }))

  .actions((self) => ({
    fetch: flow(function* () {
      self.setLoading(true);
      yield client
        .query({
          query: GameQuery,
          variables: { gameID: self.id },
          fetchPolicy: "network-only",
        })
        .then((state) => {
          if (state.errors && state.errors.length) {
            self.setErrors(state.errors.map((err) => err.message));
          } else {
            self.setErrors([]);
            self.setStateSnapshot(state.data.game);
          }
          self.setLoading(false);
        })
        .catch((err) => self.setErrors([err.message]));
    }),

    subscribe(): ZenObservable.Subscription {
      return client.subscribe({
        query: GameSubscriptionQuery,
        variables: {
          gameID: self.id,
        }
      }).subscribe((state) => {
        self.setLoading(true);
        if (state.errors) {
          self.setErrors(state.errors.map((err) => err.message));
        } else {
          self.setErrors([]);
          self.setStateSnapshot(state.data.gameChanged);
        }
        self.setLoading(false);
      });
    },
  }))


  .actions((self) => ({
    afterCreate() {
      self.fetch();

      // Create subscription
      const s = self.subscribe();

      // Fetch after reconnection and restart subscription if necessary
      wsClient.onReconnected(() => {
        self.fetch();
        if (s.closed) {
          s.unsubscribe();
          self.subscribe();
        }
      });
    },

    async moveToPosition(scene: string) {
      await client.mutate({
        mutation: gql`
          mutation GoToScene($gameID: ID!, $scene: String!) {
            gotoScene(gameID: $gameID, scene: $scene) {
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          scene,
          gameID: self.id,
        },
      });
      return;
    },

    takeAPicture(data: {
      scene: string;
      fov: number;
      h: number;
      v: number;
      picture: File;
    }) {
      client
        .mutate({
          mutation: gql`
            mutation TakeAPicture(
              $gameID: ID!
              $picture: Upload!
              $h: Float!
              $v: Float!
              $fov: Float!
              $scene: String!
            ) {
              takeAPicture(
                gameID: $gameID
                picture: {
                  picture: $picture
                  angleV: $v
                  angleH: $h
                  fieldOfView: $fov
                  scene: $scene
                }
              ) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            ...data,
            gameID: self.id,
          },
        })
    },

    deletePicture(pictureID: string) {
      client
        .mutate({
          mutation: gql`
            mutation DeletePicture($gameID: ID!, $pictureID: ID!) {
              deletePicture(gameID: $gameID, pictureID: $pictureID) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            pictureID,
            gameID: self.id,
          },
        })
    },

    tagPicture(clusterID: string, pictureID: string) {
      client
        .mutate({
          mutation: gql`
            mutation TagPicture(
              $gameID: ID!
              $pictureID: ID!
              $clusterID: ID!
            ) {
              tagPicture(
                gameID: $gameID
                pictureID: $pictureID
                clusterID: $clusterID
              ) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            pictureID,
            clusterID,
            gameID: self.id,
          },
        })
    },

    untagPicture(pictureID: string) {
      client
        .mutate({
          mutation: gql`
            mutation UntagPicture($gameID: ID!, $pictureID: ID!) {
              untagPicture(gameID: $gameID, pictureID: $pictureID) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            pictureID,
            gameID: self.id,
          },
        })
    },

    renameCluster(clusterID: string, name: string) {
      client
        .mutate({
          mutation: gql`
            mutation RenameCluster(
              $gameID: ID!
              $clusterID: ID!
              $name: String!
            ) {
              renameCluster(
                gameID: $gameID
                clusterID: $clusterID
                name: $name
              ) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            name,
            clusterID,
            gameID: self.id,
          },
        })
    },

    addReportDocument(file: File) {
      client
        .mutate({
          mutation: gql`
            mutation AddReportDocument($gameID: ID!, $file: Upload!) {
              addReportDocument(gameID: $gameID, file: $file) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            file,
            gameID: self.id,
          },
        })
    },

    deleteReportDocument(documentID: string) {
      client
        .mutate({
          mutation: gql`
            mutation DeleteReportDocument($gameID: ID!, $documentID: ID!) {
              deleteReportDocument(gameID: $gameID, documentID: $documentID) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            documentID,
            gameID: self.id,
          },
        })
    },

    commitCompletion() {
      client
        .mutate({
          mutation: gql`
            mutation CompleteGame($gameID: ID!) {
              completeGame(gameID: $gameID) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            gameID: self.id,
          },
        })
    },

    setClusterRank(clusterID: string, rank: number) {
      client
        .mutate({
          mutation: gql`
            mutation RankCluster($gameID: ID!, $clusterID: ID!, $rank: Int!) {
              rankCluster(gameID: $gameID, clusterID: $clusterID, rank: $rank) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            gameID: self.id,
            clusterID: clusterID,
            rank: rank,
          },
        })
    },

    setUnitCluster(clusterID: string, unit: string) {
      client
        .mutate({
          mutation: gql`
            mutation RenameClusterUnit(
              $gameID: ID!
              $clusterID: ID!
              $unit: String!
            ) {
              renameClusterUnit(
                gameID: $gameID
                clusterID: $clusterID
                unit: $unit
              ) {
                rejectionReason
                rejectionType
              }
            }
          `,
          variables: {
            gameID: self.id,
            clusterID: clusterID,
            unit: unit,
          },
        })
    },
  }))

  .actions((self) => ({
    setSelectedCluster(cluster: ClusterInstance) {
      self.syncSelectedCluster = SyncCluster.create({
        originalStatePath: getPath(cluster),
        autosync: true,
      });
    },

    deselectCluster() {
      self.syncSelectedCluster = null;
    },

    setClusterInEditing(cluster: ClusterInstance) {
      const syncCluster = SyncCluster.create({
        originalStatePath: getPath(cluster),
      });
      self.syncClusterInEditing = syncCluster;
    },

    deselectClusterInEditing() {
      self.syncClusterInEditing && self.syncClusterInEditing.sync();
      self.syncClusterInEditing = null;
    },
    editNameCluster() {
      self.clusterEditMode = "name";
    },
    editUnitCluster() {
      self.clusterEditMode = "unit";
    },
    exitClusterEditMode() {
      self.clusterEditMode = "";
    },
  }))

  .actions((self) => ({
    openSidebar() {
      self.sidebar = true;
    },
    closeSidebar() {
      self.sidebar = false;
    },

    showPicture(id: number) {
      self.image = id;
    },

    hidePicture() {
      self.image = -1;
    },
  }))

  .views((self) => ({
    getHotspot(hotspotID: string) {
      const hotspot =
        self.state &&
        self.state.discoveredHotspots.find(
          (hotspot: HotspotInstance) => hotspot.id === hotspotID
        );
      return hotspot || null;
    },

    get selectedCluster() {
      return self.syncSelectedCluster && self.syncSelectedCluster.state;
    },

    get clusterInEditing() {
      return self.syncClusterInEditing && self.syncClusterInEditing.state;
    },
  }))

  .views((self) => ({
    ClusterName(cluster: { id: string; name: string }) {
      return observer(() => (
        <Fragment>
          <pre>{cluster.name}</pre>
        </Fragment>
      ));
    },
  }));

export interface GameModelInstance extends Instance<typeof GameModel> {}
export interface GameModelSnapshotIn extends SnapshotIn<typeof GameModel> {}
export interface GameModelSnapshotOut extends SnapshotOut<typeof GameModel> {}
