import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import EditIcon from './EditIcon';
import { Layout } from './Layout';
import TablePaginator from './TablePaginator';


import TrashIcon from './TrashIcon';
import { SessionItemInstance } from '../model/AdminModel/SessionItem';

type SessionsListProps = {
  userID: string
  sessions: SessionItemInstance[],
  elementForPage: number
};



export default class SessionsList extends React.Component<SessionsListProps, { selectedPage: number }> {

  static defaultProps = {
    elementForPage: 5
  };


  constructor(props: SessionsListProps) {
    super(props);

    this.state = {
      selectedPage: 1
    }

  }

  getPagesNumber() {
    //TODO non viene renderizzata
    let pagesNumber = this.props.sessions.length / this.props.elementForPage;
    const resto = this.props.sessions.length % this.props.elementForPage;
    pagesNumber = parseInt(pagesNumber.toString())
    return resto !== 0 ? pagesNumber + 1 : pagesNumber;
  }

  getSessionsArray() {
    return this.props.sessions.slice(
      (this.state.selectedPage - 1) * this.props.elementForPage,
      (this.state.selectedPage - 1) * this.props.elementForPage + this.props.elementForPage
    )
  }

  render() {
    //let pagesNumber = this.getPagesNumber();
    return (
      <Layout>
        {({ components: { Table, Button, Modal, Header } }) => (
          <div style={{ width: '100%', background: 'white', margin: 'auto' }}>
            <Table className='customTable' style={{ width: '79%', margin: 'auto', paddingTop: '28px', paddingBottom: '28px' }} basic='very' celled collapsing>
              <Table.Header style={{ fontSize: '12px' }}>
                <Table.Row>
                  <Table.HeaderCell style={{ color: '#acacac', textTransform: 'uppercase', borderRightWidth: 0, borderLeftWidth: 0, paddingLeft: '14px', width: '65%' }}>
                    <FormattedHTMLMessage id="sessions.table:name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: '#acacac', textTransform: 'uppercase', borderRightWidth: 0, borderLeftWidth: 0, width: '25%' }}>
                    <FormattedHTMLMessage id="sessions.table:date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: '#acacac', textTransform: 'uppercase', borderRightWidth: 0, borderLeftWidth: 0 }}>
                    <FormattedHTMLMessage id="sessions.table:games" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: '#acacac', textTransform: 'uppercase', borderRightWidth: 0, borderLeftWidth: 0, width: '5%' }} />
                  <Table.HeaderCell style={{ color: '#acacac', textTransform: 'uppercase', borderRightWidth: 0, borderLeftWidth: 0, width: '5%' }} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.getSessionsArray().map((session, index) => (
                  <Table.Row key={index} >
                    <Table.Cell style={{ border: '1px solid rgba(172, 172, 172, 0.33)', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0, padding: '26px 14px', color: '#0d549b', fontWeight: 'bold' }}>{session.name}</Table.Cell>
                    <Table.Cell style={{ border: '1px solid rgba(172, 172, 172, 0.33)', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0, padding: '26px 14px', color: '#524e4e' }}>{session.date}</Table.Cell>
                    <Table.Cell style={{ border: '1px solid rgba(172, 172, 172, 0.33)', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0, padding: '26px 14px', color: '#524e4e' }}>{session.games.length}</Table.Cell>
                    <Table.Cell style={{ border: '1px solid rgba(172, 172, 172, 0.33)', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0, padding: '26px 14px', color: '#524e4e' }}>
                      <Link to={`/admin/users/${this.props.userID}/sessions/${session.id}`}>
                        <EditIcon />
                      </Link>
                    </Table.Cell>
                    <Table.Cell style={{ border: '1px solid rgba(172, 172, 172, 0.33)', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0 }}>
                      <Modal
                        trigger={
                          <TrashIcon style={{ color: '#f44b48' }} />
                        }
                        header={
                          <Header style={{ fontSize: '32px', color: '#f44b48', fontWeight: 'normal', borderBottom: 0, padding: 0 }}>
                            <FormattedHTMLMessage id="modal:delete-title" defaultMessage="Are you sure to delete the session?" />
                          </Header>
                        }
                        content={
                          <div style={{ margin: "46px", fontSize: '18px', color: '#524e4e' }}>
                            <FormattedHTMLMessage id="modal:delete-description" defaultMessage=" " />
                          </div>
                        }
                        actions={[
                          <Button size="big" style={{ width: '200px', height: '60px', fontWeight: 'normal', color: 'white', backgroundColor: '#a3a3a3' }} key={"dismiss"}>
                            <FormattedHTMLMessage id="modal:dismiss" />
                          </Button>,
                        ]}
                        size="small"
                        style={{
                          padding: "74px 60px",
                          backgroundColor: "white",
                          textAlign: "center",
                          width: '620px',
                          height: '324px',
                          lineHeight: 1.72
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell colSpan='5'>
                    <TablePaginator
                      selectedPage={this.state.selectedPage}
                      pagesNumber={this.getPagesNumber()}
                      onPageClick={(index: number) => this.setState({ selectedPage: index })}
                      onLeftLimitClick={() => this.setState({ selectedPage: 0 })}
                      onRightLimitClick={() => this.setState({ selectedPage: this.getPagesNumber() - 1 })}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        )}
      </Layout>
    )
  }
}
