import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const SessionItem = types.model('SessionItem')
  .props({
    id: types.string,
    name: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    games: types.optional(types.array(types.model({ id: types.string })), []),
  })

export interface SessionItemInstance extends Instance<typeof SessionItem> { }
export interface SessionItemSnapshotIn extends SnapshotIn<typeof SessionItem> { }
export interface SessionItemSnapshotOut extends SnapshotOut<typeof SessionItem> { }
